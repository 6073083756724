import { Component, Output, EventEmitter, Input } from '@angular/core';
import { UserRole } from 'sustainment-component';

// need to move this to sustainment-models

@Component({
  selector: 'app-registration-company-type',
  templateUrl: './company-type.component.html',
  styleUrls: ['./company-type.component.scss']
})
export class RegistrationCompanyTypeComponent {
  @Input() public roles: UserRole[] = [];
  @Output() public enableContinue = new EventEmitter<boolean>();
  @Output() public roleSelected = new EventEmitter<{ id: number; text: string; }[]>();

  public selectedRoles: Record<number, boolean> = {};
  public multiselectInvalid: boolean;

  public onCompanyBuyerChanged(event: Event, roleId: number): void {
    event.stopPropagation();
    this.selectedRoles[roleId] = !this.selectedRoles[roleId];
    const selectedRoles = Object.entries(this.selectedRoles)
      .filter(([, value]) => value === true)
      .map(([key]) => +key);
    this.enableContinue.emit(!!selectedRoles.length);

    const roles = this.roles.filter(e => selectedRoles.includes(e.userRoleId))

    this.roleSelected.emit(roles.map(e => { return { id: e.userRoleId, text: e.name }}));
  }
}
